<template>
    <div class="edit-profile-detail" @scroll="onScroll">
        <div class="title">세부사항</div>
        <div v-for="(section, idx) in sections" :key="idx">
            <div v-if="section.current" class="section current" @click="isCurrent">
                <Toggler v-model="company.employment_status" class="m-r-10" />
                <div class="section-title">현재 재직중</div>
            </div>
            <div v-else class="section">
                <div class="section-title required">{{ section.title }}</div>
                <SelectInput
                    :alreadySelected="company.employment_type"
                    v-if="section.selecttype && showSelectInput"
                    :options="employmentType"
                    @selected="onSelectItem"
                />
                <TextareaWithX
                    v-else-if="!section.model"
                    :isInputMode="section.inputmode"
                    :placeholder="section.placeholder"
                    :textareaRows="5"
                    class="inputbox"
                    v-model="company[section.name]"
                    :class="{ placeholder: company[section.name] }"
                />
                <div v-else class="inputbox" @click="getItem(section)" :class="{ placeholder: !company[section.name] }">
                    <img class="m-r-4" :src="require('@/assets/images/icon-search.png')" alt="" />
                    {{ company[section.name] ? company[section.name] : section.placeholder }}
                </div>
                <!--                <div class="changenotice" v-if="section.changenotice">-->
                <!--                    *변경사항이 있으시다면 매니저에게 문의주세요!-->
                <!--                </div>-->
                <div class="info-open-wrapper" v-if="section.isSelectiveOpen">
                    <div class="info-open-title">{{ section.isSelectiveOpen }}</div>

                    <div class="selector-container">
                        <div
                            class="select"
                            v-for="(opt, i) in InfoOpen"
                            :key="`${section.open_method_name}-${i}`"
                            @click="onClickSelector(section.open_method_name, i)"
                        >
                            <img
                                class="m-r-6"
                                :src="require(`@/assets/images/icons/${getIconType(i, section.open_method_name)}.png`)"
                                width="24px"
                                height="24px"
                            />
                            <span>{{ opt[section.openOption[i]] }}</span>
                            <span v-if="section.openOption[i].includes('custom')">
                                {{ custom_info_open(section)[i] }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <BottomButton :label="`저장`" @click="saveProfile" :blackBtn="true" :disabled="disabled" /> -->
    </div>
</template>

<script>
import TextareaWithX from '@/components/app/TextareaWithX'
import SelectInput from '@/components/app/SelectInput'

export default {
    name: 'JobTypeA',
    data: () => ({
        showHeaderTitle: false,
        showSelectInput: true,
        company: {
            // 직장/직업
            job_category_name: '',
            job_category_id: '',
            // 직장명
            company_name: '',
            show_company_name: null,
            // 부서
            company_department: '',
            show_company_department: null,
            // 직업
            job_name: '',
            // 직급
            company_rank: '',
            show_company_rank: null,
            // 담당업무
            company_task: '',
            // 고용형태
            employment_type: '',
            // 직장소재지
            region_shorten: '',
            company_state_name: '',
            show_region_shorten: null,
            // 현재 재직중
            employment_status: 1,
            // 기타
            company_region_id: null,
            company_state_id: null,
            company_id: null,
            company_station_id: null,
        },
        region_shorten: '',
        disabled: true,
    }),
    mounted() {
        this.set_job_category()

        if (this.edit) {
            Object.keys(this.edit).forEach(key => {
                this.company[key] = this.edit[key]
            })
        } else if (this.stage) {
            Object.keys(this.profile.company[0]).forEach(key => {
                this.company[key] = this.profile.company[0][key]
            })
        }
        this.showSelectInput = true
    },
    methods: {
        onClickSelector(key, i) {
            this.company[key] = !i
        },
        getIconType(i, key) {
            if (this.company[key] === null) return 'radio_off'
            if (i === 0) {
                if (this.company[key]) {
                    return 'radio_on'
                }
                return 'radio_off'
            } else {
                if (!this.company[key]) {
                    return 'radio_on'
                }
                return 'radio_off'
            }
        },
        async getItem(section) {
            if (section.model === 'state') {
                const item = await this.$modal.custom({
                    component: 'ModalProfileItemSteps',
                    options: {
                        model: section.model,
                    },
                })
                if (!item) return

                if (item[0].is_station) {
                    this.company.region_shorten = item[0].name + ' ' + item[1].district + '(' + item[1].name + ')'
                    this.company.company_state_id = item[0].id
                    this.company.company_station_id = item[1].id
                } else {
                    this.company.region_shorten = item[1].name + ' ' + item[1].district
                    this.company.company_state_id = item[0].id
                    this.company.company_region_id = item[1].id
                }
                this.company.company_state_name = item[0].name
            } else {
                const item = await this.$modal.custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: section.model,
                        profile: { job_category: { id: 1 } },
                    },
                })
                this.company[`${this.$case.toConst(section.model).toLowerCase()}_id`] = item.id || 0
                this.company[section.name] = item.name
            }
        },
        async onSave(key) {
            // 최초 프로필 입력시 (직업 처음 입력할때 직장 하나만!)
            try {
                if (this.edit) {
                    const company = this.profile.company

                    if (this.edit.id) {
                        const idx = company.findIndex(item => item.id === this.edit.id)
                        company.splice(idx, 1, { id: this.edit.id, ...this.company })
                    } else {
                        company.splice(this.edit.idx, 1, this.company)
                    }
                    this.$set(this.profile, 'company', company)
                } else {
                    this.$set(this.profile, 'company', [this.company])
                }
                // this.$stackRouter.pop()
            } catch (e) {
                // this.$toast.error(e.data)
                console.log(e)
                this.$toast.error(e)
            }
        },
        onScroll(event) {
            this.showHeaderTitle = event.target.scrollTop >= 52
        },
        onSelectItem(item) {
            this.company.employment_type = item.id
        },
        selectOpenMethod(opt, i) {
            if (opt === 'show_company_name') {
                this.company[opt] = i === 1 ? 2 : 1
                return
            }
            this.company[opt] = i === 1 ? 0 : 1
        },
        isCurrent() {
            this.company.employment_status = Number(!this.company.employment_status)
        },
        custom_info_open(section) {
            if (section.id === 1) {
                // 직장명일때 보여줄부분
                return [this.company.company_name, '']
            } else if (section.id === 8) {
                // 지역
                return [this.company.region_shorten, this.company.company_state_name]
            }
        },
        set_job_category() {
            this.company.job_category_name = this.job_category.name
            this.company.job_category_id = this.job_category.id
        },
    },
    props: ['type', 'job_category', 'edit', 'stage'],
    watch: {
        company: {
            handler: function () {
                const keyTest = this.requiredKeys.every(key => {
                    return this.company[key] !== ''
                })
                const infoTest = this.requiredInfo.every(key => {
                    return this.company[key] < 3 && this.company[key] !== null
                })

                if (keyTest && infoTest) {
                    this.$emit('disabled', false)
                } else {
                    // this.disabled = true
                    this.$emit('disabled', true)
                }
            },
            deep: true,
        },
    },
    computed: {
        profile() {
            return this.$store.getters.profile || {}
        },
        employmentType() {
            return Object.keys(this.$profile.employmentType).map(key => this.$profile.employmentType[key])
        },
        sections() {
            const jobCategory = {
                id: 0,
                title: '직장/직업',
                name: 'job_category_name',
                isRequired: true,
                isSelectiveOpen: false,
                placeholder: '스타트업',
                changenotice: true,
                inputmode: true,
                model: 'jobCategory',
            }
            const companyName = {
                id: 1,
                title: '직장명',
                name: 'company_name',
                isRequired: true,
                // isSelectiveOpen: '정보공개 방법',
                openOption: ['customopen', 'partclose'],
                open_method_name: 'show_company_name',
                placeholder: '바닐라브릿지',

                changenotice: true,
                inputmode: true,
                model: 'company',
            }
            const isCurrent = {
                id: 2,
                current: true,
            }
            const department = {
                id: 3,
                title: '부서',
                name: 'company_department',
                isRequired: true,
                isSelectiveOpen: '정보공개 방법',
                openOption: ['allopen', 'close'],
                open_method_name: 'show_company_department',
                placeholder: '전략기획실 / 디자인팀 등',
                changenotice: false,
                inputmode: true,
            }
            const occupation = {
                id: 4,
                title: '직업',
                name: 'job_name',
                isRequired: true,
                isSelectiveOpen: false,
                placeholder: '회계사/IT기획자/기자 등',
                changenotice: false,
                inputmode: true,
            }
            const position = {
                id: 5,
                title: '직급',
                name: 'company_rank',
                isRequired: true,
                isSelectiveOpen: '정보공개 방법',
                openOption: ['allopen', 'close'],
                open_method_name: 'show_company_rank',
                placeholder: '파트장/대리/00년차 등',
                changenotice: false,
                inputmode: true,
            }
            const jobDuty = {
                id: 6,
                title: '담당업무',
                name: 'company_task',
                isRequired: true,
                isSelectiveOpen: false,
                inputmode: this.type === 'A' ? true : false,
                placeholder: this.type === 'B' ? '하시는 업무에 대해서 적어주세요.' : '경영전략 기획 등',
                changenotice: false,
            }
            const jobType = {
                id: 7,
                title: '고용형태',
                name: 'employment_type',
                isRequired: true,
                isSelectiveOpen: false,
                placeholder: '선택',
                changenotice: false,
                selecttype: true,
                inputmode: true,
            }
            const jobLocation = {
                id: 8,
                title: '직장 소재지',
                name: 'region_shorten',
                placeholder: '역명 검색',
                isRequired: true,
                isSelectiveOpen: '정보공개 범위',
                openOption: ['customopen', 'customclose'],
                open_method_name: 'show_region_shorten',
                changenotice: false,
                inputmode: true,
                model: 'state',
            }
            if (this.type === 'A') {
                return [
                    jobCategory,
                    companyName,
                    isCurrent,
                    department,
                    occupation,
                    position,
                    jobDuty,
                    jobType,
                    jobLocation,
                ]
            } else {
                return [jobCategory, isCurrent, jobDuty]
            }
        },

        InfoOpen() {
            const open = {
                customopen: '전체 공개 :',
                allopen: '공개',
            }
            const close = {
                partclose: '부분공개(매니저와 협의)',
                close: '비공개(매니저에게만 공개)',
                customclose: '부분 공개 :',
            }
            return [open, close]
        },
        requiredKeys() {
            return [
                'job_category_name',
                'company_name',
                'company_department',
                'job_name',
                'company_rank',
                'company_task',
                'employment_type',
                'region_shorten',
                'company_state_name',
            ]
        },
        requiredInfo() {
            return ['show_company_rank', 'show_company_department', 'show_region_shorten']
        },
    },
    components: {
        // BottomButton,
        TextareaWithX,
        SelectInput,
    },
}
</script>
<style scoped lang="scss">
//정보공개 범위 부분에 대한 css
.info-open-wrapper {
    margin: 12px 0px;

    .info-open-title {
        margin: 4px 0;
        &::after {
            color: $blue-primary;
            content: '*';
        }
    }
    .info-open-item {
        display: flex;
        align-items: center;
        input {
            width: 20px;
            height: 20px;
        }
        .info-open-option {
            font-size: 14px;
            line-height: 24px;
            margin: 4px 4px;
            &label {
                margin: 0px 4px;
            }
        }
    }
}
// 하단버튼에 가려지는 부분 띄우는 기능
.edit-profile-detail:last-child {
    padding-bottom: 80px;
}

.selector-container {
    .select {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        font-size: 14px;

        i {
            font-size: 26px;

            // font-
        }
    }
}
</style>
